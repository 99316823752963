import { YStack } from '@bounty/creators-design-system'
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import { createStateContext } from '../../factory'
import { useAuth } from '../../hooks'
import {
  BANNER_HEIGHT,
  MOBILE_BANNER_HEIGHT,
  MOBILE_NAV_HEIGHT,
  NAV_HEIGHT,
} from './WebConstants'

export const [WebWrapperProvider, useWebWrapper] = createStateContext({
  isCreatorsMode: true,
  showBottomSignUpBanner: true,
  showWebSwitcher: true,
})

// Putting here so we don't cause rerenders of the whole tree on scroll
const HookWrapper = () => {
  const [{ isAuthed, isInitializing }] = useAuth()
  const [{ showWebSwitcher, showBottomSignUpBanner }, setWebWrapper] =
    useWebWrapper()
  const scroll = useWindowScroll()
  const currentShowWebSwitch = scroll.y < 45

  useEffect(() => {
    if (isInitializing) return

    if (
      isAuthed &&
      (showWebSwitcher === true || showBottomSignUpBanner === true)
    ) {
      setWebWrapper((x) => ({
        ...x,
        showWebSwitcher: false,
        showBottomSignUpBanner: false,
      }))
      return
    }
  }, [
    showWebSwitcher,
    isAuthed,
    setWebWrapper,
    showBottomSignUpBanner,
    isInitializing,
  ])

  useEffect(() => {
    if (isInitializing || isAuthed) return

    if (currentShowWebSwitch !== showWebSwitcher) {
      setWebWrapper((x) => ({ ...x, showWebSwitcher: currentShowWebSwitch }))
    }
  }, [
    setWebWrapper,
    scroll,
    showWebSwitcher,
    currentShowWebSwitch,
    isAuthed,
    isInitializing,
  ])

  return null
}

export const WebWrapper = ({ children }: { children: ReactNode }) => {
  const { pathname } = useRouter()
  const [{ showWebSwitcher, isCreatorsMode }, setWebWrapper] = useWebWrapper()

  useEffect(() => {
    const newIsCreatorsMode = pathname.startsWith('/brands') === false

    if (newIsCreatorsMode !== isCreatorsMode) {
      setWebWrapper((x) => ({ ...x, isCreatorsMode: newIsCreatorsMode }))
    }
  }, [pathname, isCreatorsMode, setWebWrapper])

  useEffect(() => {
    if (isCreatorsMode === false) {
      setWebWrapper((x) => ({ ...x, showBottomSignUpBanner: false }))
    } else {
      setWebWrapper((x) => ({ ...x, showBottomSignUpBanner: true }))
    }
  }, [isCreatorsMode, setWebWrapper])

  return (
    <YStack
      pt={MOBILE_NAV_HEIGHT + (showWebSwitcher ? MOBILE_BANNER_HEIGHT : 0)}
      $gtMd={{
        pt: NAV_HEIGHT + (showWebSwitcher ? BANNER_HEIGHT : 0),
      }}
      // Needed to force footer below the fold pixel perfect
      minHeight="100vh"
    >
      <HookWrapper />
      {children}
    </YStack>
  )
}
