import { useRef } from 'react'
import { ViewStyle } from 'react-native'
import { Svg, G, Path, Defs, ClipPath, Rect } from 'react-native-svg'
import { themed } from './themed'
import { ColorTokens, ThemeTokens } from '@tamagui/core'

export type ShopifyIconProps = {
  /**
   * This is for the width. Height is computed for you.
   */
  size?: number
  color?: (ColorTokens | ThemeTokens) | null
  style?: ViewStyle
}

/**
 * When size is given, it is talking about width. We compute the height from the viewbox ratio
 * because it needs both width and height to be universal.
 */
const computeHeight = (size: number) => {
  return size * (24 / 22)
}

export const ShopifyIconComponent = ({
  style,
  color: colorProp,
  size = 24,
}: ShopifyIconProps) => {
  const randomClip = useRef(`${Math.random() * 1000}`)
  const color = colorProp ?? 'black'
  const computedHeight = computeHeight(size)

  return (
    <Svg
      width={size}
      height={computedHeight}
      viewBox="0 0 22 24"
      fill="none"
      style={style}
    >
      <G clipPath={`url(#${randomClip.current})`}>
        <Path
          d="M14.8431 2.81033C14.8413 2.81095 14.5751 2.89324 14.125 3.03288C14.0502 2.78914 13.9392 2.48929 13.7815 2.1882C13.2728 1.2176 12.5273 0.703321 11.6271 0.702074C11.6259 0.702074 11.6253 0.702074 11.624 0.702074C11.5617 0.702074 11.4993 0.707678 11.437 0.713289C11.4108 0.681496 11.384 0.649704 11.3553 0.619782C10.9632 0.200249 10.4595 -0.00421746 9.85736 0.0132371C8.69414 0.0468994 7.53591 0.886594 6.5971 2.37833C5.9357 3.4281 5.43388 4.74717 5.29113 5.76763C3.95585 6.18093 3.02141 6.4708 3.00146 6.47703C2.32759 6.68835 2.3064 6.70955 2.21788 7.34477C2.15118 7.82539 0.387646 21.4642 0.387646 21.4642L15.0208 23.9952V2.78041C14.9485 2.78539 14.8836 2.79848 14.8431 2.81033ZM11.4644 3.85698C10.6902 4.09698 9.84489 4.3588 8.99648 4.62124C9.23523 3.708 9.68718 2.79848 10.2426 2.20254C10.4489 1.98061 10.7382 1.73376 11.0804 1.59226C11.4027 2.26301 11.4719 3.21428 11.4644 3.85698ZM9.8798 0.787471C10.1528 0.78186 10.3829 0.841704 10.5792 0.970743C10.265 1.13407 9.96084 1.36846 9.67596 1.67391C8.93726 2.46622 8.37123 3.69677 8.14557 4.88368C7.44115 5.10186 6.75232 5.31506 6.11897 5.51142C6.51855 3.64317 8.08448 0.839211 9.8798 0.787471ZM7.61694 11.431C7.69549 12.6759 10.9707 12.9477 11.1546 15.8638C11.2992 18.1579 9.93778 19.7269 7.97601 19.851C5.62152 19.9993 4.32552 18.6104 4.32552 18.6104L4.82422 16.4878C4.82422 16.4878 6.12894 17.4721 7.1731 17.4061C7.85507 17.3631 8.09881 16.8082 8.0745 16.4155C7.97227 14.7916 5.30546 14.887 5.13653 12.219C4.99502 9.97355 6.46931 7.69822 9.72333 7.49251C10.9769 7.41334 11.619 7.73313 11.619 7.73313L10.8747 10.5165C10.8747 10.5165 10.045 10.1387 9.06068 10.2005C7.61757 10.2933 7.60198 11.2035 7.61694 11.431ZM12.2374 3.61761C12.2287 3.02852 12.1582 2.20939 11.884 1.50124C12.7667 1.6683 13.2012 2.66695 13.3844 3.26228C13.0447 3.36763 12.6576 3.48732 12.2374 3.61761Z"
          fill={color}
        />
        <Path
          d="M15.5045 23.9378L21.5749 22.4287C21.5749 22.4287 18.9611 4.75717 18.9449 4.63561C18.9281 4.51467 18.8221 4.44735 18.7348 4.43987C18.6476 4.43239 16.9383 4.40682 16.9383 4.40682C16.9383 4.40682 15.896 3.3951 15.5045 3.01172V23.9378Z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id={randomClip.current}>
          <Rect
            width="21.2571"
            height="24"
            fill={color}
            transform="translate(0.371428)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export const ShopifyIcon = themed(ShopifyIconComponent)
