import {
  Avatar as AvatarCore,
  AvatarProps as AvatarPropsCore,
  AvatarImageProps,
} from 'tamagui'

export type AvatarProps = AvatarPropsCore &
  Pick<AvatarImageProps, 'accessibilityLabel' | 'src'>

export const Avatar = ({ src, accessibilityLabel, ...props }: AvatarProps) => {
  return (
    <AvatarCore circular {...props}>
      <AvatarCore.Image
        resizeMode="contain"
        accessibilityLabel={accessibilityLabel}
        src={src}
      />
      <AvatarCore.Fallback backgroundColor="$primary.500" />
    </AvatarCore>
  )
}
