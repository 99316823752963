import { WebFooter } from '@bounty/creators-common/components/web/WebFooter'
import { WebNavigation } from '@bounty/creators-common/components/web/WebNavigation'
import { WebSignUpBanner } from '@bounty/creators-common/components/web/WebSignUpBanner'
import {
  WebWrapper,
  WebWrapperProvider,
} from '@bounty/creators-common/components/web/WebWrapper'
import { ReactNode } from 'react'

export function Layout({ children }: { children: ReactNode }) {
  return (
    <WebWrapperProvider>
      <WebNavigation />
      <WebWrapper>{children}</WebWrapper>
      <WebFooter />
      <WebSignUpBanner />
    </WebWrapperProvider>
  )
}
