import {
  Button,
  H6,
  Logo,
  Paragraph,
  ShopifyIcon,
  Text,
  XStack,
  YStack,
  LinkButton,
} from '@bounty/creators-design-system'
import { Link } from 'solito/link'
import { useAuth } from '../../hooks'
import { useBrandSignUpModal } from '../../screens/brands/components/SignUpModal'
import { useWebWrapper } from './WebWrapper'
// import { IosDownloadIconDark } from './WebIcons'

const FOOTER_ITEMS = [
  {
    title: 'Creators',
    items: [
      {
        title: 'Shop',
        url: '/shop',
      },
      {
        title: 'Community Rules',
        url: '/content-guidelines',
      },
      {
        title: 'Login',
        url: '/sign-in',
      },
    ],
  },
  {
    title: 'Brands',
    items: [
      {
        title: 'Learn More',
        url: '/brands',
      },
      {
        title: 'Login',
        url: 'https://brands.earnbounty.com/sign-up',
      },
    ],
  },
  {
    title: 'More',
    items: [
      {
        title: 'Blog',
        url: '/blog',
      },
      // {
      //   title: 'Affiliates',
      //   url: 'https://bounty.tapfiliate.com/',
      // },
      {
        title: 'Careers',
        url: 'https://bountyapp.notion.site/Careers-at-Bounty-246fe626721249949a10b6e35fbaaf16',
      },
      {
        title: 'Support',
        url: 'mailto:support@thebountyappinc.zohodesk.com?subject=Bounty App Help',
      },
    ],
  },
  {
    title: 'Legal',
    items: [
      {
        title: 'Creator Terms',
        url: '/creator-terms-of-use',
      },
      {
        title: 'Brand Terms',
        url: '/brand-terms-of-use',
      },
      {
        title: 'Privacy Policy',
        url: '/privacy-policy',
      },
      {
        title: 'Copyright Dispute Policy',
        url: '/copyright-dispute-policy',
      },
    ],
  },
]

export const WebFooter = () => {
  const [{ isCreatorsMode }] = useWebWrapper()
  const [, setOpen] = useBrandSignUpModal()
  const [{ isInitializing, isAuthed }] = useAuth()
  const footerItems = FOOTER_ITEMS.filter((group) => {
    if (isInitializing === false && isAuthed) {
      return group.title === 'Legal'
    } else {
      return true
    }
  })
  return (
    <YStack
      space="$6"
      py="$12"
      $gtMd={{
        py: '$16',
      }}
      px="$6"
      backgroundColor="$neutral.900"
    >
      <YStack
        width="100%"
        $gtMd={{
          maxWidth: 1200,
          mx: 'auto',
        }}
      >
        <YStack
          space="$8"
          width="100%"
          $gtMd={{
            // row-reverse when native app
            flexDirection: isCreatorsMode ? 'row' : 'row-reverse',
            justifyContent: 'space-between',
          }}
        >
          {/* TODO: When native app */}
          {/* <YStack space="$4" alignItems="flex-start">
            <H6 size="$lg" color="$neutral.50">
              Get the app
            </H6>
            <Link href="/">
              <IosDownloadIconDark height={40} />
            </Link>
          </YStack> */}
          {isCreatorsMode === false && (
            <YStack space="$4" alignItems="flex-start">
              <H6 size="$lg" color="$neutral.50">
                Get started today
              </H6>
              <LinkButton
                event={'Web Footer Install on Shopify Button Clicked'}
                variant="outline"
                colorScheme="whiteAlpha"
                borderColor={'$neutral.50'}
                buttonTextProps={{
                  color: '$neutral.50',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
                href="https://form.typeform.com/to/i03A8JAw"
                target="_blank"
              >
                <Text mr="$2" lineHeight={1}>
                  <ShopifyIcon size={16} color={'$neutral.50'} />
                </Text>
                Schedule a Demo
              </LinkButton>
            </YStack>
          )}

          <XStack fw="wrap">
            {footerItems.map((item) => {
              return (
                <YStack
                  space="$4"
                  marginBottom="$8"
                  width="50%"
                  $gtMd={{ width: 172 }}
                  key={item.title}
                >
                  <H6 size="$lg" color="$whiteAlpha.800">
                    {item.title}
                  </H6>
                  <YStack space="$3">
                    {item.items.map((x) => {
                      return (
                        <Link href={x.url} key={x.url}>
                          <Paragraph color="$neutral.50" cursor={'pointer'}>
                            {x.title}
                          </Paragraph>
                        </Link>
                      )
                    })}
                  </YStack>
                </YStack>
              )
            })}
          </XStack>
        </YStack>
        <YStack
          space="$5"
          pt="$7"
          borderTopWidth={1}
          borderColor="$neutral.600"
        >
          <Logo size={125} color="$neutral.50" />
          <Paragraph color="$whiteAlpha.600" size="$sm">
            {' '}
            © {new Date().getFullYear()} The Bounty App Inc. All rights
            reserved.
          </Paragraph>
        </YStack>
      </YStack>
    </YStack>
  )
}
