import { Button, Heading, XStack, YStack } from '@bounty/creators-design-system'
import { Link } from 'solito/link'
import { useWebWrapper } from './WebWrapper'

export const WebSignUpBanner = () => {
  const [{ showBottomSignUpBanner }] = useWebWrapper()

  if (showBottomSignUpBanner === false) return null

  return (
    <YStack
      backgroundColor={'$neutral.50'}
      py="$3"
      px="$4"
      // @ts-expect-error - Web only prop
      position="fixed"
      zIndex={100}
      alignItems="flex-start"
      bottom={0}
      left={0}
      right={0}
      space="$2"
      boxShadow="0px -4px 20px rgba(52, 64, 83, 0.05)"
      borderTopColor={'$neutral.300'}
      borderTopWidth={1}
      $gtMd={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 80,
      }}
    >
      <Heading size="$sm" color="$neutral.800" $gtMd={{ fontSize: '$2xl' }}>
        Get paid to post about your favorite brands!
      </Heading>
      <XStack space="$2">
        {/* TODO: Download iOS link */}
        {/* <IosDownloadIconLight height={32} /> */}
        <Link href="https://creators.earnbounty.com/sign-up">
          <Button
            event="Creators Web Sign Up Banner Sign Up Clicked"
            size="$sm"
          >
            Sign up
          </Button>
        </Link>
      </XStack>
    </YStack>
  )
}
